<template>
  <div class="root-manage">
    <div class="is-hidden-touch">
      <router-view />
    </div>
    <section class="section is-main-section is-hidden-desktop">
      <div class="notification is-info">
        <div>
          <b-icon icon="desktop-mac" custom-size="default" />
          <span> 1024px以上の画面でご利用ください。 </span>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {}
</script>

<style>
</style>